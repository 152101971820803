import { render, staticRenderFns } from "./MenuDisplay.vue?vue&type=template&id=3532b254&scoped=true"
import script from "./MenuDisplay.vue?vue&type=script&lang=js"
export * from "./MenuDisplay.vue?vue&type=script&lang=js"
import style0 from "./MenuDisplay.vue?vue&type=style&index=0&id=3532b254&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3532b254",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
installComponents(component, {VIcon,VList,VListGroup,VListItem,VListItemAction,VListItemContent,VListItemTitle})
