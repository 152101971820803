<template>
  <div class="app-footer" style="display:none">
    <div class="app-footer__inner">
      <div class="app-footer-left">
      
      </div>
      <div class="app-footer-right">
      
      </div>
    </div>
  </div>
</template>

<script>



import { library } from "@fortawesome/fontawesome-svg-core";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";

library.add(faEllipsisV);
export default {
  name: "Footer",
  components: {

  }
};
</script>
